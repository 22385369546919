<template>
  <div class="transication-container relative relative w-500 rounded-[10px] bg-white transition-all">
    <div class="title flex items-center">
      <i class="iconfont icon-chengjiaoshuju text-20 text-gray"></i>
      <span class="ml-10 mr-5">{{ $t('成交数据') }}</span>
      <mytooltip placement="bottom" :class="'absolute z-10'">
        <i class="iconfont icon-kongxinwenhao absolute z-10 text-18 text-gray" style="right: -20px"></i>
        <template #content>
          <div class="py-15 text-14 text-gray-light">
            <p>{{ $t('站内成交数据库统计') }}</p>
          </div>
        </template>
      </mytooltip>
      <!-- <i class="iconfont icon-kongxinwenhao text-18 text-gray"></i> -->
    </div>
    <div class="transication-chart-container px-20">
      <div class="transication-chart-dom h-220 w-460"></div>
    </div>
  </div>
</template>

<script setup>
import * as echarts from 'echarts/core'
import { GridComponent, VisualMapComponent, TooltipComponent, LegendComponent, DataZoomComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import { UniversalTransition } from 'echarts/features'
import { CanvasRenderer, SVGRenderer } from 'echarts/renderers'

import { newIndexItemVolumeTrend } from '~/api/steam'
import mytooltip from '~/components/common/mytooltip.vue'

const showOnlineChart = ref(true)

onMounted(() => {
  let dateList = []
  let valueList = []
  let maxList = []
  newIndexItemVolumeTrend().then(res => {
    dateList = res.data.map(item => {
      return item[0]
    })
    valueList = res.data.map(item => {
      return item[2]
    })
    maxList = res.data.map(item => {
      return currencyTransform(item[1])
    })
    echartInit(dateList, valueList, maxList)
  })
})

const echartInit = (dateList, valueList, maxList) => {
  echarts.use([GridComponent, VisualMapComponent, LineChart, CanvasRenderer, UniversalTransition, TooltipComponent, LegendComponent, DataZoomComponent, SVGRenderer])
  const dom = document.querySelector('.transication-chart-dom')
  const myChart = echarts.init(dom, null, {
    renderer: 'svg'
  })
  const option = {
    grid: {
      right: 0,
      top: 20
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        label: {
          backgroundColor: '#6a7985'
        }
      }
    },
    legend: {
      show: true,
      zlevel: 10000,
      right: 20,
      top: 20
      // top: 50
      // formatter: function (name) {
      //   console.log('name', name)
      // }
      // data: ['峰值', '平均值']
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    dataZoom: [
      {
        show: false,
        realtime: true,
        start: 90,
        end: 100,
        xAxisIndex: [0, 1]
      },
      {
        type: 'inside',
        realtime: true,
        start: 90,
        end: 100,
        xAxisIndex: [0, 1]
      }
    ],
    xAxis: [
      {
        type: 'category',
        boundaryGap: false,
        data: dateList
      }
    ],
    yAxis: [
      {
        type: 'value',
        position: 'right'
      },
      {
        type: 'value',
        position: 'left'
      }
    ],
    series: [
      {
        name: '成交额',
        type: 'line',
        // stack: '总量',
        showSymbol: false,
        smooth: 0.3,
        lineStyle: {
          width: 1
        },
        // areaStyle: {},
        data: maxList,
        yAxisIndex: 0
      },
      {
        name: '成交量',
        type: 'line',
        // stack: '总量',
        showSymbol: false,
        smooth: 0.3,
        lineStyle: {
          width: 1
        },
        // areaStyle: {},
        data: valueList,
        yAxisIndex: 1
      }
    ]
  }
  myChart.setOption(option)
}
</script>

<style lang="scss">
.transication-container {
  .title {
    position: absolute;
    top: 20px;
    left: 20px;
  }
}
</style>
